import { styled } from '@vw-marketing/us-components';

export const StyledSkeletonWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: solid 1px #dfe4e8;
  min-width: 100%;
  height: 540px;
`;

export const StyledTopSection: any = styled.div`
  display: flex;
  padding: 30px 20px 0px 20px;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const StyledDetailsWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const StyledBottomSection: any = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: flex-start;
  padding: 20px;
`;
