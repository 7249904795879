import React from 'react';
import {
  StyledBottomSection,
  StyledDetailsWrapper,
  StyledSkeletonWrapper,
  StyledTopSection,
} from './styled';
import { SkeletonBox } from '../skeleton-box';

export const SkeletonCard: React.FC = () => {
  return (
    <StyledSkeletonWrapper>
      <StyledTopSection>
        <SkeletonBox width="50%" height="36px" />
        <SkeletonBox width="100%" height="109px" />
        <StyledDetailsWrapper>
          <SkeletonBox width="67%" height="24px" />
          <SkeletonBox width="100%" height="24px" />
          <SkeletonBox width="75%" height="24px" />
        </StyledDetailsWrapper>
        <SkeletonBox height="23px" width="40%" />
      </StyledTopSection>
      <StyledBottomSection>
        <SkeletonBox height="44px" width="100%" />
        <SkeletonBox height="44px" width="100%" />
      </StyledBottomSection>
    </StyledSkeletonWrapper>
  );
};
