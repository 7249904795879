import { Breakpoints, styled } from '@vw-marketing/us-components';

interface OffersModalProps {
  isMobile: boolean;
}

export const StyledModalWrapper = styled.div<OffersModalProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  height: ${(props: OffersModalProps) => props.isMobile ? 'auto': '822px'};
  padding: 20px 0px 20px 20px;

  @media (max-width: ${Breakpoints.b560}px) {
    padding: 0;
  }

  .model-card-wrapper,
  .header-group,
  .no-results-wrapper {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.b2560}px),
    (max-width: ${Breakpoints.b1920}px),
    (max-width: ${Breakpoints.b1280}px),
    (max-width: ${Breakpoints.b960}px) {
    .header-group,
    .no-results-wrapper {
      width: 100%;
    }
  }

  ${props =>
    props.isMobile
      ? `
      .details-tab-container {
        margin-left: 22px;
      }
    `
      : ``}
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export const CloseWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
