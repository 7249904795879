// This code handles all the data changes in the app regarding incentive store

// Even though the store is split in different files every reducer this
// needs to return StoreModel since all the store are merged in one
import { History } from 'history';
import isBrowser from 'is-browser';
import { SalesEventOffersIds, SpecialEventConfig } from '../../..';
import { ModelConfig } from '../../../typings/model-config';
import { AppConstants } from '../../../utils/app-constants';
import { addUrlParam, setModelFromHash } from '../../../utils/navigation-url';
import {
  AttributeModel,
  AuthoredLmaDetailModel,
  DealerModel,
  ErrorHandlingModel,
  LocationModel,
  ModalZipDataModel,
  ModelTag,
  OfferInformation,
  OffersModel,
  TypeMap,
} from '../../typings/incentive-store';
import { StoreModel } from '../../typings/store';
import { clearFilters, filterOffers, setFilterInformation } from '../filters';
import { filtersToUrl } from '../filters/query';
import { getInitialState } from '../initial-state';
import { getModalByOfferId, handleNewFilters } from './reducer-helpers';

interface CloneStoreDataModel {
  readonly initialSatate: StoreModel;
  readonly history: History;
  readonly specialEvent?: SpecialEventConfig;
  readonly specialOffersRedesignEnable?: boolean;
  readonly salesEventOffersIds?: SalesEventOffersIds;
  readonly modelKey?: string;
  readonly trim?: string;
  readonly isTrimLevel?: boolean;
}

interface SetInitialDataModel {
  readonly pageName: string;
  readonly offersData: TypeMap<OfferInformation>;
  readonly dealers: DealerModel[];
  readonly dealer: DealerModel;
  readonly history: History;
  readonly zip: string;
  readonly ipZip: string;
  readonly errorHandling: ErrorHandlingModel[];
  readonly modelsConfig: ModelConfig | undefined;
  readonly specialEvent: SpecialEventConfig;
  readonly specialOffersRedesignEnable: boolean;
  readonly salesEventOffersIds?: SalesEventOffersIds;
  readonly modelKey?: string;
  readonly trim?: string;
  readonly isTrimLevel?: boolean;
}

interface SetInitialDataModelTier2 {
  readonly offersData: TypeMap<OfferInformation>;
  readonly serviceOffersData: OffersModel[];
  readonly dealers: DealerModel[];
  readonly dealer: DealerModel;
  readonly authoredLmaDetail: AuthoredLmaDetailModel;
  readonly location: LocationModel;
  readonly history: History;
  readonly zip: string;
  readonly ipZip: string;
  readonly errorHandling: ErrorHandlingModel[];
  readonly modelsConfig: ModelConfig | undefined;
}

interface UpdateStoreByZip {
  readonly offersData: TypeMap<OfferInformation>;
  readonly dealers: DealerModel[];
  readonly dealer: DealerModel;
  readonly history: History;
  readonly zip: string;
  readonly errorHandling: ErrorHandlingModel[];
  readonly modelsConfig: ModelConfig | undefined;
  readonly offerId?: number;
  readonly specialEvent?: SpecialEventConfig;
  readonly specialOffersRedesignEnable?: boolean;
  readonly salesEventOffersIds?: SalesEventOffersIds;
  readonly modelKey?: string;
  readonly trim?: string;
  readonly isTrimLevel?: boolean;
}

interface UpdateStoreByZipTier2 {
  readonly offersData: TypeMap<OfferInformation>;
  readonly serviceOffersData: OffersModel[];
  readonly dealers: DealerModel[];
  readonly dealer: DealerModel;
  readonly authoredLmaDetail: AuthoredLmaDetailModel;
  readonly location: LocationModel;
  readonly history: History;
  readonly zip: string;
  readonly errorHandling: ErrorHandlingModel[];
  readonly modelsConfig: ModelConfig | undefined;
  readonly offerId?: number;
}

interface UpdateAppliedFilterModel {
  readonly filter: AttributeModel;
  readonly history: History;
  readonly pendingProcessState: boolean;
  readonly salesEventOffersIds?: SalesEventOffersIds;
  readonly specialOffersRedesignEnable: boolean;
}

interface ClearFiltersModel {
  readonly history: History;
  readonly pendingProcessState: boolean;
}

interface SetModalFormData {
  readonly offer: OffersModel;
  readonly modelTag: ModelTag;
  readonly dealer: DealerModel;
  readonly formType: string;
  readonly formName: string;
}

interface SetDRModalData {
  active: boolean;
}

/**
 * Here are all the reducer functions for Incentive store
 */
export function getReducer(): any {
  return {
    /**
     * Clone data to the store
     * @param currentState Previous state
     * @param initialSatate Obj with all the data needed to start the store
     * @param history React history
     */
    CLONE_STORE_DATA: (
      currentState: StoreModel,
      {
        initialSatate,
        history,
        specialEvent,
        specialOffersRedesignEnable,
        salesEventOffersIds,
        modelKey,
        trim,
        isTrimLevel
      }: CloneStoreDataModel,
    ) => {
      const commonValues = {
        ...currentState,
        ...initialSatate,
        ssr: !isBrowser,
        isStoreCloned: true,
      };

      // Add the zip/lma to the URL
      if (initialSatate.tier2) {
        addUrlParam(
          history,
          'lma',
          initialSatate.authoredLmaDetail.vanityUrlName,
        );
      } else if (
        initialSatate.pageName !== AppConstants.Tier1SimplifiedVersion
      ) {
        addUrlParam(history, 'zip', initialSatate.zip);
      }

      const newModalData =
        initialSatate.offerId &&
        AppConstants.Tier1SimplifiedVersion !== currentState.pageName &&
        initialSatate.dealer
          ? getModalByOfferId(
              initialSatate.dealer,
              initialSatate.offersData,
              initialSatate.offerId,
            )
          : currentState.modalFormData;

      if (
        initialSatate.tier2 ||
        initialSatate.pageName !== AppConstants.Tier1SimplifiedVersion
      ) {
        // set all the neaded filters for tier 1 and tier 2 to the store
        const setFilterInfoObj = setFilterInformation(
          history,
          initialSatate.offersData,
          specialOffersRedesignEnable ?? false,
          salesEventOffersIds,
          specialEvent,
          modelKey,
          trim,
          isTrimLevel
        );

        return {
          ...commonValues,
          ...setFilterInfoObj,
          modalFormData: newModalData
            ? newModalData
            : initialSatate.modalFormData,
        };
      }

      return {
        ...commonValues,
      };
    },

    /**
     * Set initial data used for this app
     * @param currentState Previous state
     * @param pageName Page Name
     * @param offersData All offers from feed
     * @param dealers List of dealers by zip
     * @param dealer Dealer aor
     * @param history React history
     * @param zip Postal Code
     * @param ipZip Zip code from de service
     * @param errorHandling Handle the erros on the page
     */
    SET_INITIAL_DATA: (
      currentState: StoreModel,
      {
        pageName,
        offersData,
        dealers,
        dealer,
        history,
        zip,
        ipZip,
        errorHandling,
        modelsConfig,
        specialEvent,
        specialOffersRedesignEnable,
        salesEventOffersIds,
        modelKey,
        trim,
        isTrimLevel
      }: SetInitialDataModel,
    ) => {
      // If the process is CSR the first time currentState
      // is empty so the initial state is set instead
      const state = currentState ? currentState : getInitialState();

      const commonValues = {
        ...state,
        offersData,
        dealers,
        dealer,
        zip,
        ipZip,
        status: AppConstants.StoreStatusLoaded,
        ssr: !isBrowser,
        tier2: false,
        pageName,
        zipNoDealers: !dealers.length,
        errorHandling,
        modelsConfig,
      };

      if (pageName !== AppConstants.Tier1SimplifiedVersion) {
        if (isBrowser) {
          setModelFromHash(history);
        }

        // set all the neaded filters for tier 1 and tier 2 to the store
        const setFilterInfoObj = setFilterInformation(
          history,
          offersData,
          specialOffersRedesignEnable,
          salesEventOffersIds,
          specialEvent,
          modelKey,
          trim,
          isTrimLevel
        );
        return {
          ...commonValues,
          ...setFilterInfoObj,
        };
      }

      return {
        ...commonValues,
      };
    },

    /**
     * Set initial data used for this app
     * @param currentState Previous state
     * @param offersData All offers from feed
     * @param dealers List of dealers by zip
     * @param dealer Dealer aor
     * @param history React history
     * @param zip Postal code
     * @param errorHandling Handle the erros on the page
     */
    UPDATE_STORE_BY_ZIP: (
      currentState: StoreModel,
      {
        offersData,
        dealers,
        dealer,
        history,
        zip,
        errorHandling,
        modelsConfig,
        offerId,
        specialEvent,
        specialOffersRedesignEnable,
        salesEventOffersIds,
        modelKey,
        trim,
        isTrimLevel
      }: UpdateStoreByZip,
    ) => {
      const newModalData =
        offerId && AppConstants.Tier1SimplifiedVersion !== currentState.pageName
          ? getModalByOfferId(dealer, offersData, offerId)
          : currentState.modalFormData;

      const commonValues = {
        ...currentState,
        offersData,
        dealers,
        dealer,
        zip,
        zipNoDealers: !dealers.length,
        pendingProcessState: false,
        errorHandling,
        modelsConfig,
        modalFormData: newModalData || currentState.modalFormData,
      };

      if (currentState.pageName !== AppConstants.Tier1SimplifiedVersion) {
        // set all the neaded filters for tier 1 and tier 2 to the store
        const setFilterInfoObj = setFilterInformation(
          history,
          offersData,
          specialOffersRedesignEnable ?? false,
          salesEventOffersIds,
          specialEvent,
          modelKey,
          trim,
          isTrimLevel
        );
        // Set zip value to url
        addUrlParam(history, 'zip', zip);

        return {
          ...commonValues,
          ...setFilterInfoObj,
        };
      }

      return {
        ...commonValues,
      };
    },

    /**
     * Updata the app state using the last filter selected
     * @param currentState Previous state
     * @param filter Selected filter on the sidebar
     * @param history React history
     */
    UPDATE_APPLIED_FILTER: (
      currentState: StoreModel,
      {
        filter,
        history,
        pendingProcessState = false,
        specialOffersRedesignEnable,
        salesEventOffersIds,
      }: UpdateAppliedFilterModel,
    ): StoreModel => {
      let updatedFilters = handleNewFilters(
        currentState.appliedFilters,
        filter,
      );

      // Check if the filter was selected or unselected
      const toFilter = currentState.appliedFilters.filter(
        ({ value }: any) => value === filter.value,
      );
      let appliedFilters;

      if (specialOffersRedesignEnable && filter.value !== 'all') {
        appliedFilters = toFilter.length
          ? currentState.appliedFilters.filter(({ value }: any) => {
              if (
                value === filter.value &&
                (filter.filterKey === 'offers' ||
                  filter.filterKey === 'special-event')
              ) {
                return filter;
              }
              return value !== filter.value;
            })
          : updatedFilters;
      } else if (specialOffersRedesignEnable && filter.value === 'all') {
        appliedFilters = currentState.appliedFilters.filter(
          filter => !['offers', 'special-event'].includes(filter.filterKey),
        );
      } else {
        appliedFilters = toFilter.length
          ? currentState.appliedFilters.filter(
              ({ value }: any) => value !== filter.value,
            )
          : [...currentState.appliedFilters, filter];
      }

      // Updata the filters in the URL
      if (history) {
        filtersToUrl(appliedFilters, history, currentState.tier2);
      }

      const filteredOffers = filterOffers(
        currentState.offersData,
        appliedFilters,
        salesEventOffersIds,
      );

      const offersAmount = filteredOffers.reduce(
        (next: number, { offerCount }: OfferInformation) => next + offerCount,
        0,
      );

      return {
        ...currentState,
        appliedFilters,
        pendingProcessState,
        filteredOffers,
        offersAmount,
      };
    },

    /**
     * Set filters to the initial state
     * @param currentState Previous state
     * @param history React history
     */
    CLEAR_FILTERS: (
      currentState: StoreModel,
      { history, pendingProcessState = false }: ClearFiltersModel,
    ): StoreModel => {
      if (history) {
        // Clear url filters
        history.replace(history.location.pathname);
        // Add the zip/lma to the URL
        if (currentState.tier2) {
          addUrlParam(
            history,
            'lma',
            currentState.authoredLmaDetail.vanityUrlName,
          );
        } else {
          addUrlParam(history, 'zip', currentState.zip);
        }
      }

      return {
        ...currentState,
        pendingProcessState,
        ...clearFilters(currentState.offersData),
      };
    },

    /**
     * Set store to the initial state
     * @param currentState Previous state
     * @param history React history
     */
    RESET_STORE: (currentState: StoreModel, history: History): StoreModel => {
      if (history) {
        // Clear url filters
        history.replace(history.location.pathname);
        addUrlParam(history, 'zip', currentState.zip);
      }
      const state = getInitialState();

      return { ...state };
    },

    /**
     * Show or close the modal filter for mobile
     * @param currentState State store
     * @param mobileModalFilterActivated Modal filter state
     */
    ACTIVATE_MOBILE_FILTER_MODAL: (
      currentState: StoreModel,
      mobileModalFilterActivated: boolean,
    ): StoreModel => ({
      ...currentState,
      mobileModalFilterActivated,
    }),

    /**
     * Default Close Contact Dealer Form Data
     * @param currentState State store
     */
    CLOSE_CONTACT_DEALER_FORM: (currentState: StoreModel) => ({
      ...currentState,
      zip: currentState.zip,
      modalFormData: {
        active: false,
        offer: undefined,
        dealer: undefined,
        formType: '',
        formName: '',
      },
    }),

    /**
     * Set Contact Dealer Form Data
     * @param currentState State store
     * @param offer An specific offer
     * @param dealer Aor dealer
     * @param modelTag tag for sales event
     * @param formType Form type
     * @param formName Form name
     */
    SET_MODAL_FORM_DATA: (
      currentState: StoreModel,
      { offer, dealer, modelTag, formType, formName }: SetModalFormData,
    ) => ({
      ...currentState,
      modalFormData: {
        active: true,
        offer,
        dealer,
        modelTag,
        formType,
        formName,
      },
    }),

    /**
     * Set DR Modal Data
     */
    SET_DR_MODAL_DATA: (
      currentState: StoreModel,
      { active }: SetDRModalData,
    ) => ({
      ...currentState,
      modalDRData: {
        active,
      },
    }),

    /**
     * Update the modal state
     * @param currentState State store
     * @param modalZipState is an object with information to update the modalZipState
     */
    UPDATE_MODAL_ZIP_STATE: (
      currentState: StoreModel,
      modalZipState: ModalZipDataModel,
    ): StoreModel => ({
      ...currentState,
      modalZipState: {
        ...currentState.modalZipState,
        ...modalZipState,
      },
      pendingProcessState: false,
    }),

    /**
     * Set if there is pending data comming from some endpoint
     * @param currentState State store
     * @param pendingProcessState Pending process state
     */
    SET_PENDING_PROCESS_STATE: (
      currentState: StoreModel,
      pendingProcessState: boolean,
    ): StoreModel => ({
      ...currentState,
      pendingProcessState,
    }),

    /**
     * Set initial data for offers modal
     * @param currentState State store
     * @param modelKey Model key
     */
    SET_MODAL_INITIAL_DATA: (
      currentState: StoreModel,
      modelKey: string,
      trim?: string,
    ): StoreModel => {
      if (trim) {
        return {
          ...currentState,
          appliedFilters: [
            ...currentState.appliedFilters,
            {
              isSpecialEvent: false,
              filterKey: 'model',
              text: modelKey,
              value: modelKey,
            },
            {
              isSpecialEvent: false,
              filterKey: 'trim',
              text: trim.toLowerCase(),
              value: trim.toLowerCase(),
            },
          ],
        };
      } else {
        return {
          ...currentState,
          appliedFilters: [
            ...currentState.appliedFilters,
            {
              isSpecialEvent: false,
              filterKey: 'model',
              text: modelKey,
              value: modelKey,
            },
          ],
        };
      }
    },

    // =====================Tier 2 ======================= //
    /**
     * Set initial data used for this app
     * @param currentState Previous state
     * @param offersData All offers from feed
     * @param serviceOffersData All service offers from feed
     * @param dealers List of dealers by zip
     * @param dealer Dealer aor
     * @param authoredLmaDetail Object from dealer associations
     * @param location Location information
     * @param history React history
     * @param zip Postal Code
     * @param ipZip Zip code from de service
     * @param errorHandling Handle the erros on the page
     */
    SET_INITIAL_DATA_TIER2: (
      currentState: StoreModel,
      {
        offersData,
        serviceOffersData,
        dealers,
        dealer,
        authoredLmaDetail,
        location,
        history,
        zip,
        ipZip,
        errorHandling,
        modelsConfig,
      }: SetInitialDataModelTier2,
      specialOffersRedesignEnable = false,
      salesEventOffersIds = {},
    ) => {
      // If the process is CSR the first time currentState
      // is empty so the initial state is set instead
      const state = currentState ? currentState : getInitialState();
      // set all the neaded filters for tier 1 and tier 2 to the store
      const setFilterInfoObj = setFilterInformation(
        history,
        offersData,
        specialOffersRedesignEnable,
        salesEventOffersIds,
      );

      return {
        ...state,
        offersData,
        serviceOffersData,
        dealers,
        dealer,
        zip,
        ipZip,
        status: AppConstants.StoreStatusLoaded,
        authoredLmaDetail,
        location: location ? location : { error: true },
        ssr: !isBrowser,
        ...setFilterInfoObj,
        tier2: true,
        errorHandling,
        modelsConfig,
        zipNoDealers: !dealers.length,
      };
    },

    /**
     * Set initial data used for this app
     * @param currentState Previous state
     * @param offersData All offers from feed
     * @param serviceOffersData All service offers from feed
     * @param dealers List of dealers by zip
     * @param dealer Dealer aor
     * @param authoredLmaDetail Object from dealer associations
     * @param location Location information
     * @param history React history
     * @param zip Postal Code
     * @param errorHandling Handle the erros on the page
     */
    UPDATE_STORE_BY_ZIP_TIER2: (
      currentState: StoreModel,
      {
        offersData,
        serviceOffersData,
        dealers,
        dealer,
        authoredLmaDetail,
        location,
        history,
        zip,
        errorHandling,
        modelsConfig,
        offerId,
      }: UpdateStoreByZipTier2,
      specialOffersRedesignEnable = false,
      salesEventOffersIds = {},
    ) => {
      // set all the neaded filters for tier 1 and tier 2 to the store
      const setFilterInfoObj = setFilterInformation(
        history,
        offersData,
        specialOffersRedesignEnable,
        salesEventOffersIds,
      );
      // Set lma value to url
      addUrlParam(history, 'lma', authoredLmaDetail.vanityUrlName);
      const newModalData = offerId
        ? getModalByOfferId(dealer, offersData, offerId)
        : currentState.modalFormData;
      return {
        ...currentState,
        offersData,
        serviceOffersData,
        dealers,
        dealer,
        authoredLmaDetail,
        location: location ? location : { error: true },
        zip,
        zipNoDealers: !dealers.length,
        ...setFilterInfoObj,
        pendingProcessState: false,
        errorHandling,
        modelsConfig,
        modalFormData: newModalData || currentState.modalFormData,
      };
    },
  };
}
