import React, { useEffect, useRef, useState } from 'react';

import { useStore } from '../../../hooks-store/store';
import { OfferInformation } from '../../../hooks-store/typings/incentive-store';
import { useModelsConfig } from '../../../services/use-models-config';
import { AppConstants } from '../../../utils/app-constants';
import { NewModelOfferSection } from '../model-offer-section/offer-card/NewModelOfferSection';

export const NewOffersContainer = (): JSX.Element | null => {
  const [scrollY, setScrollY] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [store] = useStore();
  const modelOverview = useModelsConfig();

  const getMSRPValue = (offerInformation: OfferInformation) => {
    for (const carlineId in modelOverview) {
      const carData = modelOverview[carlineId];
      let modelName = carData.name;
      modelName = modelName?.replace(/Golf R/i, 'Golf');

      if (offerInformation.modelDisplayName === modelName) {
        return carData.price ?? '';
      }
    }
    return '';
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const restoreScroll = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const maxScrollY =
          document.documentElement.scrollHeight - window.innerHeight;
        const newScrollY = Math.min(
          scrollY,
          maxScrollY,
          containerHeight - window.innerHeight,
        );
        window.scrollTo(0, newScrollY);
      }
    };

    setTimeout(restoreScroll, 0);
  }, [store.filteredOffers]);

  return (
    <div ref={containerRef}>
      {store.filteredOffers &&
        store.filteredOffers.map((offerInformation: OfferInformation) => (
          <NewModelOfferSection
            key={offerInformation.slug}
            offerInformation={offerInformation}
            modelMsrp={getMSRPValue(offerInformation)}
            dealer={store.dealer}
            isTier1={
              !store.tier2 &&
              store.pageName !== AppConstants.Tier1SimplifiedVersion
            }
          />
        ))}
    </div>
  );
};
