import React from 'react';
import {
  LayoutWrapper,
  StyledSkeletonShowroomWrapper,
  StyledSliderWrapper,
} from './styled';
import { SectionWithHeader } from '../../pages/section-with-header';
import { useTranslations } from '../../services/use-translations';
import { Breakpoints, SlickSlider } from '@vw-marketing/us-components';
import { SkeletonCard } from '../skeleton-card';

const NUMBER_OF_SKELETON_CARDS = 7;

const slickSettings = {
  responsive: [
    {
      breakpoint: Breakpoints.b1600,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: Math.floor(3.5),
      },
    },
    {
      breakpoint: Breakpoints.b960,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: Math.floor(2.5),
      },
    },
    {
      breakpoint: Breakpoints.b560,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const SkeletonShowroom: React.FC = () => {
  const translations = useTranslations().tier1simplifiedVersion.localOffers;

  return (
    <StyledSkeletonShowroomWrapper>
      <LayoutWrapper>
        <SectionWithHeader
          title={translations.offersBtnTitle}
          showButton={false}
          isLoading
        >
          <StyledSliderWrapper>
            <SlickSlider
              visibleCards={4.5}
              visibleCardOnTablet={2.5}
              showArrows={false}
              slickSettings={slickSettings}
            >
              {Array.from({ length: NUMBER_OF_SKELETON_CARDS }).map(
                (_, index) => (
                  <SkeletonCard key={index} />
                ),
              )}
            </SlickSlider>
          </StyledSliderWrapper>
        </SectionWithHeader>
      </LayoutWrapper>
    </StyledSkeletonShowroomWrapper>
  );
};
