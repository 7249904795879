import {
  ButtonAppearance,
  ButtonCustomLink,
  ChevronRight,
  DisableFocusContainer,
  LinkButton,
  ModalContainer,
  TextAppearance,
  TextCustom,
  TextTag,
} from '@vw-marketing/us-components';
import React, { useEffect } from 'react';
import {
  cancelButtonStyle,
  cardSubtitleTextStyle,
  dealerCTATextStyle,
  stepIconMap,
  titleTextStyle,
} from './helpers';
import {
  StyledDealerWebSiteContainer,
  StyledInterstitialContainer,
  StyledInterstitialStepCards,
  StyledInterstitialTitle,
  StyledInterstitialDealerWebSite,
  StyledInterstitialCards,
  Container,
} from './styles';
import InterstitialCard from './interstitial-card';
import InterstitialCarCard from './interstitial-car-card';
import { useTrackingManager } from '../../context/use-tracking-manager';
import { useTranslations } from '../../services/use-translations';
import {
  onVWBasicDRLayerloadEvent,
  onVWBasicVehicleButtonClickEvent,
} from '../../utils/tagging-helpers';
import { useModelsConfig } from '../../services/use-models-config';

export interface ModalBuildDealInterstitialProps {
  active: boolean;
  vehicle: any;
  handOffContent: string;
  onCloseHandler: () => void;
  vehiclePosition?: number;
  informationVendor?: string;
  obLink?: string;
  isLinkModified?: boolean;
  symbol?: string;
  vehicleImage?: string;
}

const ModalBuildDealInterstitial: React.FC<ModalBuildDealInterstitialProps> = props => {
  const {
    active,
    onCloseHandler,
    handOffContent,
    vehicle,
    informationVendor,
    obLink,
    isLinkModified,
    symbol,
    vehicleImage,
  } = props;
  const modalsContent = useTranslations().modals;
  const handOffContentMap = {
    NoOnlineBuying: modalsContent.modalHandOffNoBuying,
    onlineBuying: modalsContent.modalHandOffOnlineBuying,
  };
  const translations = handOffContentMap[handOffContent];
  const trackingManager = useTrackingManager();
  const modelsConfig = useModelsConfig();
  const modalCtaLabel = isLinkModified
    ? translations.dealerWebSiteModified
    : translations.dealerWebSite;

  const onDealerButtonClick = vehicleModel => {
    const trackingLink = obLink
      ? {
          link: {
            url: obLink,
            name: 'online buying',
            newTab: true,
            isDealerPage: true,
          },
        }
      : {};

    const trackingPartner = informationVendor
      ? { partner: { informationVendor } }
      : {};

    const additionalTrackingData = {
      ...trackingLink,
      ...trackingPartner,
    };

    onVWBasicVehicleButtonClickEvent(
      vehicleModel,
      {
        Type: 'Button',
        Name: modalCtaLabel,
        ItemCount: 1,
        ItemPosition: 1,
      },
      modelsConfig,
      trackingManager,
      additionalTrackingData,
    );

    onCloseHandler();
  };

  useEffect(() => {
    onVWBasicDRLayerloadEvent(trackingManager);
  }, []);

  return (
    <Container>
      <ModalContainer
        active={active}
        onCloseHandler={onCloseHandler}
        ariaLabel="Build my deal Modal"
        closeButtonAriaLabel="Close Build my deal Modal"
      >
        <StyledInterstitialContainer className="modal-build-deal-interstitial">
          <StyledInterstitialTitle className="modal-build-deal-interstitial-title">
            <TextCustom
              appearance={TextAppearance.headline300}
              tag={TextTag.h2}
              style={titleTextStyle}
            >
              {translations.title}
            </TextCustom>
          </StyledInterstitialTitle>
          <StyledInterstitialStepCards className="modal-build-deal-interstitial-cards">
            <div className="vehicle-card-wrapper">
              <InterstitialCarCard
                vehicle={vehicle}
                translations={translations}
                currency={symbol}
                vehicleImage={vehicleImage}
              />
            </div>
            <StyledInterstitialCards className="modal-build-deal-interstitial-cards">
              <TextCustom {...cardSubtitleTextStyle}>
                {translations.subtitle}
              </TextCustom>
              {translations.steps.map(step => (
                <InterstitialCard
                  classes={`${step.id} step`}
                  Icon={stepIconMap[step.icon]}
                  description={step.descriptionMarkdown}
                />
              ))}
            </StyledInterstitialCards>
            <StyledDealerWebSiteContainer className="modal-build-deal-interstitial-button">
              <DisableFocusContainer>
                <StyledInterstitialDealerWebSite
                  className="hand-off-dealer-link"
                  isLarge={handOffContent === 'onlineBuying'}
                >
                  <ButtonCustomLink
                    appearance={ButtonAppearance.Primary}
                    href={obLink}
                    target={'_blank'}
                    onClick={onDealerButtonClick.bind(null, vehicle)}
                  >
                    <TextCustom {...dealerCTATextStyle}>
                      {modalCtaLabel}
                    </TextCustom>
                    <ChevronRight variant="small" />
                  </ButtonCustomLink>
                  <LinkButton onClick={onCloseHandler} isInline>
                    <TextCustom {...cancelButtonStyle}>Cancel</TextCustom>
                  </LinkButton>
                </StyledInterstitialDealerWebSite>
              </DisableFocusContainer>
            </StyledDealerWebSiteContainer>
          </StyledInterstitialStepCards>
        </StyledInterstitialContainer>
      </ModalContainer>
    </Container>
  );
};

export default ModalBuildDealInterstitial;
