import {Breakpoints, styled} from '@vw-marketing/us-components';

interface ContainerProps {
  isModal?: boolean;
}

export const StyledModalFormWrapper: any = styled.div`
  padding: 20px;
  padding-top: 45px;
  @media (min-width: ${Breakpoints.b960}px) {
    padding: 80px 70px;
  }
  z-index: 999;
`;

export const ModalFormWrapper: any = styled.div`
  position: fixed;
  z-index: 999;
`;

export const Container = styled.div<ContainerProps>`
 ${(props: ContainerProps) =>
      props.isModal ? `
        & .internal-modal-layer-wrapper {
          @media (min-width: 960px) {
            padding: 100px 0;
          }
        }

        & .close-wrapper {
        @media (min-width: 960px) {
            width: auto;
          }
        }
      `
      : ''

  }
`;
