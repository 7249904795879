import React, { useRef } from 'react';

import {
  defaulInitialValues,
  defaultValidationSchemaConfig,
  FailedView,
  getDynamicFormValidationSchema,
  ModalContainer,
  ModalContainerProps,
  modelInitialValues,
  ProcessingView,
  SuccessView,
} from '@vw-marketing/us-components';
import { Formik } from 'formik';
import _ from 'lodash';

import {
  addUrlParamWithPush,
  removeUrlParam,
} from '../../../utils/navigation-url';
import {
  DealerModel,
  ModelTag,
  OffersModel,
} from '../../../hooks-store/typings/incentive-store';
import {
  onHandleLoadTracking,
  onHandlerServiceLayerLoadEvent,
} from '../../../utils/tagging-helpers';
import { useIsNationalOffers } from '../../../services/use-is-national-offer';
import { AppConstants } from '../../../utils/app-constants';
import { cleanUpCharacters, disabledScroll } from '../../../utils/general';
import { FeatureAppTrackingManager } from '../../../context/use-tracking-manager';
import { getStringifiedServiceConfigsServiceConfig } from '../../../services/get-stringified-service-configs-service-config';
import { getSubmitHandler } from './submit-handler';
import { Container, ModalFormWrapper, StyledModalFormWrapper } from './styled';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import { useFeatureServices } from '../../../services/use-feature-services';
import { useHistory } from '../../../services/use-history';
import ModalFormContainer from './modal-form-container';

export interface ModalFormProps extends ModalContainerProps {
  dealer?: DealerModel;
  formName: string;
  formType: string;
  offer?: OffersModel;
  modelTag?: ModelTag;
  tier2: boolean;
  trackingManager: FeatureAppTrackingManager;
  zipCode: string;
}

export interface ModalFormState {
  state: 'filling' | 'submitting' | 'failed' | 'success';
}

export const ModalForm = ({
  active,
  dealer,
  formName,
  formType,
  offer,
  onCloseHandler,
  modelTag,
  tier2,
  trackingManager,
  zipCode,
}: ModalFormProps) => {
  const [internalActive, setInternalActive] = React.useState(active);
  const [formState, setFormState] = React.useState<ModalFormState>({
    state: 'filling',
  });

  const {
    mockIds,
    mocksBaseUrl,
    faServicesUrl,
    faHostServerUrl = '',
    mode
  } = useFeatureAppConfig();
  const isModal = mode === 'modal';
  const isNationalOffers = useIsNationalOffers();
  const {
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
  } = useFeatureServices();
  const history = useHistory();

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );

  formType =
    isNationalOffers && formType === AppConstants.OfferDetailsFormType
      ? AppConstants.NationalOffersOnlyFormType
      : formType;

  const isScheduleService =
    formType === AppConstants.ScheduleServiceFormType ||
    formType === AppConstants.ScheduleServiceDetailFormType;

  const isDetailsForm =
    formType === AppConstants.OfferDetailsFormType ||
    formType === AppConstants.NationalOffersOnlyFormType;

  let testDriveComment = useRef(false);
  const updateTestDriveComment = value => {
    testDriveComment.current = value;
  };

  // const formLabel = 'Contact Dealer';
  const onSubmitForm = getSubmitHandler(
    dealer,
    offer,
    setFormState,
    zipCode,
    faServicesUrl,
    formName,
    tier2,
    serviceConfigsServiceConfig,
    mockIds,
    mocksBaseUrl,
    trackingManager,
    isScheduleService,
    formType,
    testDriveComment,
  );

  const onHandleDetailsUrl = (action: 'add' | 'remove') => {
    action === 'add'
      ? addUrlParamWithPush(history, 'offerId', offer?.dealId.toString() || '')
      : removeUrlParam(history, 'offerId');
  };

  React.useEffect(() => {
    setInternalActive(active);
    disabledScroll(active);
    active &&
      !isScheduleService &&
      onHandleLoadTracking(
        'load',
        formType,
        formName,
        offer,
        dealer,
        trackingManager,
      );
    isDetailsForm && onHandleDetailsUrl('add');
    active &&
      isScheduleService &&
      onHandlerServiceLayerLoadEvent(
        formType,
        formName,
        dealer,
        trackingManager,
        offer,
      );
  }, [active]);

  /**
   * Handle Close, helps to clean data after close action
   * avoiding close when form is submitting
   */
  const handleClose = () => {
    if (formState.state === 'submitting') {
      return;
    }
    setFormState({ state: 'filling' });
    isDetailsForm && onHandleDetailsUrl('remove');
    !isScheduleService &&
      onHandleLoadTracking(
        'close',
        formType,
        formName,
        offer,
        dealer,
        trackingManager,
      );
    onCloseHandler();
  };

  const validationSchema = getDynamicFormValidationSchema(
    defaultValidationSchemaConfig,
    isScheduleService,
  );

  return (
    <Container isModal={isModal}>
      <ModalFormWrapper>
        {formState.state === 'submitting' && <ProcessingView active={true} />}
        {active && (
          <Formik
            initialValues={
              isScheduleService
                ? { ...defaulInitialValues, ...modelInitialValues }
                : defaulInitialValues
            }
            initialStatus={false}
            onSubmit={onSubmitForm}
            validationSchema={validationSchema}
            validateOnMount={true}
            isInitialValid={false}
            enableReinitialize
          >
            <ModalContainer
              active={internalActive}
              onCloseHandler={handleClose}
              hideCloseButton={formState.state === 'submitting'}
            >
              <StyledModalFormWrapper>
                {formState.state === 'filling' ||
                formState.state === 'submitting' ? (
                  <ModalFormContainer
                    dealer={dealer}
                    formName={formName}
                    formType={formType}
                    handleClose={handleClose}
                    modelTag={modelTag}
                    offer={cleanUpCharacters(offer)}
                    tier2={tier2}
                    trackingManager={trackingManager}
                    updateTestDriveComment={updateTestDriveComment}
                  />
                ) : formState.state === 'success' ? (
                  <SuccessView
                    imageUrl={offer?.mediaImageUrl}
                    imageAlt={offer?.modelDisplayName}
                    imagePlacegholder={AppConstants.PlaceHolder960x432 as string}
                    srcErrorImage={faHostServerUrl + AppConstants.Ngw6Logo960x432}
                    okHandler={handleClose}
                  />
                ) : (
                  <FailedView
                    formName={formName}
                    setFormStateHandler={() => setFormState({ state: 'filling' })}
                  />
                )}
              </StyledModalFormWrapper>
            </ModalContainer>
          </Formik>
        )}
      </ModalFormWrapper>
    </Container>
  );
};
