import { styled } from '@vw-marketing/us-components';

interface StyledOfferTypeLabelProps {
  readonly backgroundColor: string;
  readonly textColor: string;
}

export const StyledOfferCardWrapper: any = styled.div`
  width: 100%;
  border: 1px solid #dfe4e8;
  margin-top: 15px;
  margin-bottom: 50px;
`;

export const StyledInformationOfferContainer: any = styled.div`
  width: 100%;
  padding: 30px 20px 20px;
`;

export const StyledOfferMicroText: any = styled.div`
  width: 100%;
  color: #515a5e;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledOfferTypeLabelWrapper: any = styled.div`
  width: 100%;
  position: relative;
`;

interface StyledBlackContainerProps {
  specialOffersRedesignEnable: boolean;
}

export const StyledBlackContainer: any = styled.div<StyledBlackContainerProps>`
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  ${props => props.specialOffersRedesignEnable ? "left: 10px" : "right: 50%"};
  transform: translate(${props => props.specialOffersRedesignEnable ? "10px" : "50%"}, -50%);
  padding: ${props => props.specialOffersRedesignEnable ? "0 0" : "0 10px"};
`;

export const StyledOfferTypeLabel: any = styled.div<StyledOfferTypeLabelProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
`;
