import { useReactiveVar } from '@apollo/client';
import isBrowser from 'is-browser';
import React, { useState } from 'react';
import { headerData } from '../../hooks-store/local-storage';
import { StickyHeaderContext } from '../../services/use-sticky-header';
import {
  StyledPositionWrapper,
  StyledStickyHeader,
  StyledStickyHeaderContainer,
} from './styles';

interface StickyHeaderProps {
  bottomShadow?: boolean;
  children?: React.ReactNode;
  noPadding?: boolean;
}

export const StickyHeader: React.FC<StickyHeaderProps> = ({
  children,
  noPadding,
  bottomShadow,
}) => {
  const [toggle, setToggle] = useState(true);
  const initScroll = isBrowser ? window.scrollY : 0;
  const [scrollOffset, setScrollOffset] = useState(initScroll);
  const { activeModal } = useReactiveVar(headerData);

  React.useEffect(() => {
    if (isBrowser) {
      const handleScroll = () => {
        const { scrollY } = window;
        setScrollOffset(scrollY);
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return;
  }, []);

  React.useEffect(() => {
    setToggle(scrollOffset < 135);
  }, [scrollOffset]);

  return (
    <StyledStickyHeaderContainer
      active={toggle}
      activeModal={activeModal}
      bottomShadow={bottomShadow}
      className="sticky-header-container"
    >
      <StyledPositionWrapper
        id="sticky-position"
        className={'sticky-header-position'}
        active={toggle}
        noPadding={noPadding}
        activeModal={activeModal}
      >
        <StyledStickyHeader
          className={`sticky-header-scroll ${!toggle && 'hidden'} up`}
          active={toggle}
          activeModal={activeModal}
        >
          <StickyHeaderContext.Provider
            value={{ isSticky: !toggle, isScrollDirectionUp: false }}
          >
            {children}
          </StickyHeaderContext.Provider>
        </StyledStickyHeader>
      </StyledPositionWrapper>
    </StyledStickyHeaderContainer>
  );
};
