import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StyledModelCardWrapperProps {
  emptyOffersModal?: boolean;
}

export const StyledModelOfferSectionWrapper: any = styled.div`
  width: 100%;
`;

export const NewStyledOfferSectionWrapper: any = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;

  @media (max-width: ${Breakpoints.b1600}px) and (min-width: ${Breakpoints.b960}px) {
    gap: 20px;
  }

  @media (max-width: ${Breakpoints.b960}px) and (min-width: ${Breakpoints.b560}px) {
    gap: 10px;
  }

  @media (max-width: ${Breakpoints.b560}px) {
    flex-direction: column;
    width: 100vw;
    padding-bottom: 0;
  }
`;

export const StyledModelCardWrapper = styled.div<StyledModelCardWrapperProps>`
  min-width: 400px;
  width: 100%;
  flex: ${props => (props.emptyOffersModal ? 1 : 0.5)};

  @media (max-width: ${Breakpoints.b1600}px) and (min-width: ${Breakpoints.b960}px) {
    min-width: 300px;
  }

  @media (max-width: ${Breakpoints.b560}px) {
    min-width: 100vw;
  }
`;

export const StyledOfferCardWrapper: any = styled.div`
  width: 100%;
  flex: 2;
  flex-wrap: wrap;
  overflow-x: hidden;

  @media (max-width: ${Breakpoints.b560}px) {
    flex-direction: column;
    margin: 0 20px;
    margin-top: -30px;
    width: auto;
  }
`;
