import { History } from 'history';
import isBrowser from 'is-browser';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTrackingManager } from '../../../context/use-tracking-manager';
import { updateStoreDataTier2 } from '../../../fetch-data/tier-2';
import { getDealerAssociationsByLmaId } from '../../../fetch-data/tier-2/format';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../hooks-store/store';
import { IncentiveStoreModel } from '../../../hooks-store/typings/incentive-store';
import { StoreModel } from '../../../hooks-store/typings/store';
import { getStringifiedServiceConfigsServiceConfig } from '../../../services/get-stringified-service-configs-service-config';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import { useFeatureServices } from '../../../services/use-feature-services';
import { useHistory } from '../../../services/use-history';
import { ModelStructureContext } from '../../../services/use-model-structure';
import { ModelStructure } from '../../../typings/general';
import { AppConstants } from '../../../utils/app-constants';
import { getModelStructure, navigateTier1 } from '../../../utils/general';
import { getLmaVanityUrl, getTestMode } from '../../../utils/navigation-url';
import { onHandlerFilterSeachLoad } from '../../../utils/tagging-helpers';
import { ModalForm } from '../../forms/modal-form';
import { LoaderSpinnerModal } from '../../loader-spinner';
import { ModalZip } from '../../modal-zip';

interface PageLoadProps {
  readonly children: React.ReactNode;
  readonly applicationData: StoreModel;
  readonly page: string;
  readonly baseUrl?: string;
  readonly offerId?: number;
}

export const PageLoad: React.FC<PageLoadProps> = (
  props,
): JSX.Element | null => {
  const { children, applicationData, offerId } = props;

  // Call different services used to apply fetches and update the store data
  const {
    faServicesUrl,
    mockIds,
    mocksBaseUrl,
    mappedTrimName,
  } = useFeatureAppConfig();
  const {
    sofaRedirectPageName,
    sofaRedirectFAName,
    modelOrder,
    specialOffersRedesignEnable,
    salesEventOffersIds
  } = useFeatureAppConfig();
  const history = useHistory();
  const testMode = getTestMode(history);

  const {
    'locale-service': localeService,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager,
    'navigation-service': navigationService,
  } = useFeatureServices();
  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );
  const {
    configs: { 'graphql-server': graphQLServer },
  } = serviceConfigProvider;
  const trackingManager = useTrackingManager();
  const lmaVanityUrl = getLmaVanityUrl(history);
  const [modelStructure, setModelStructure] = React.useState<
    ModelStructure | undefined
  >(undefined);

  const [
    store,
    {
      [IncentiveConstants.CloneStoreData]: cloneStoreData,
      [IncentiveConstants.CloseContactDealerForm]: closeContactDealerForm,
      [IncentiveConstants.SetPendingProcessState]: setPendingProcessState,
      [IncentiveConstants.UpdateStoreByZipTier2]: updateStoreByZip,
    },
  ] = useStore();

  /**
   * Tracking function
   * @param store
   */
  const successResultCallback = (store: IncentiveStoreModel) => {
    isBrowser && onHandlerFilterSeachLoad(trackingManager, store);
  };

  /**
   * Update the page information using the zipcode if neccessary
   * @param zipCode Zip code value
   */
  const updateStoreHandle = (zipCode: string, offerId?: number) => {
    if (zipManager.getZipCookie() && zipManager.getZipCookie() !== zipCode) {
      setPendingProcessState(true);
      updateStoreDataTier2({
        zip: zipCode,
        localeService,
        serviceConfigsServiceConfig: serviceConfigsServiceConfig as string,
        updateStoreByZip,
        zipManager,
        history: history as History,
        faServicesUrl,
        mockIds,
        mocksBaseUrl,
        mappedTrimName,
        generalmodelOrder: modelOrder,
        offerId,
        successResultCallback,
        modelsConfig: store.modelsConfig,
        graphQLServer,
      });
    }
  };

  /**
   * Before clone data some data is added to start the app in the CSR
   */
  const cloneStoreDataHandler = (zipCode: string, zipCookieExist: boolean) => {
    if (testMode === AppConstants.HideFeaturedOffers) {
      applicationData.offersData = {};
    }
    const initialData = {
      ...applicationData,
      zip: zipCode,
      modalZipState: {
        activeZipModal: !zipCookieExist,
      },
    };

    cloneStoreData(initialData, history, null, specialOffersRedesignEnable, salesEventOffersIds);
    updateStoreHandle(zipCode, (zipCookieExist && offerId) || undefined);
  };
  const updateModelStructure = () => {
    if (isBrowser) {
      try {
        let configStructure = getModelStructure(store.modelsConfig);
        if (configStructure) {
          const dealerModelOrder = getDealerAssociationsByLmaId(
            store.dealer?.lmaId || '',
          );
          const dealerAssociation = _.keyBy(
            (dealerModelOrder && dealerModelOrder.lmaData.modelOrder) ||
              modelOrder,
            'modelName',
          );
          configStructure = _.omitBy(
            configStructure,
            (value: string[], key: string) =>
              dealerAssociation[key] && value ? false : true,
          );
          setModelStructure(configStructure);
        }
      } catch (e) {
        console.log('ModelConfig Error ', e);
      }
    }
  };

  useEffect(() => {
    if (!store.isStoreCloned) {
      if (isBrowser && store.status === AppConstants.StoreStatusLoaded) {
        if (zipManager.getZipCookie()) {
          cloneStoreDataHandler(zipManager.getZipCookie(), true);
        } else if (lmaVanityUrl) {
          cloneStoreDataHandler(store.zip ? store.zip : store.ipZip, false);
        } else {
          try {
            zipManager
              .updateZipInformation('', store.ipZip)
              .catch(() => {
                console.log('Errors fetching detectBrowserLocation');
              })
              .finally(() => {
                cloneStoreDataHandler(
                  zipManager.getZip(),
                  zipManager.getZipCookie() ? true : false,
                );
              });
          } catch (error) {
            cloneStoreDataHandler(store.zip ? store.zip : store.ipZip, false);
            console.log('zipManager location error', error);
          }
        }
      }
    }
    updateModelStructure();
  }, []);

  useEffect(() => {
    updateModelStructure();
  }, [store.zip]);

  if (
    store.status === AppConstants.StoreStatusLoaded &&
    store.isStoreCloned &&
    (!store.dealers.length || !store.authoredLmaDetail.vanityUrlName)
  ) {
    navigateTier1({
      navigationService,
      pageName: sofaRedirectPageName,
      FAName: sofaRedirectFAName,
    });
  }

  return (
    <div id="dealersOffersFA">
      {children}
      <ModelStructureContext.Provider value={modelStructure}>
        <ModalForm
          tier2={store.tier2}
          offer={store.modalFormData.offer}
          dealer={store.modalFormData.dealer || store.dealer}
          formType={store.modalFormData.formType}
          formName={store.modalFormData.formName}
          active={store.modalFormData.active}
          onCloseHandler={closeContactDealerForm}
          zipCode={store.zip}
          trackingManager={trackingManager}
        />
      </ModelStructureContext.Provider>
      {store.modalZipState.activeZipModal && (
        <ModalZip
          active={store.modalZipState.activeZipModal}
          trackingManager={trackingManager}
          offerId={offerId}
        />
      )}
      {(!store.isStoreCloned || store.pendingProcessState) && (
        <LoaderSpinnerModal />
      )}
    </div>
  );
};
