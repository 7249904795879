import React from 'react';

import {
  LayoutWrapper,
  StyledInputZipContainer,
  StyledInputZipWrapper,
  StyledLandingWrapper,
} from './styled';
import {
  LoaderContainer,
  LoaderSpinner,
} from '../../components/loader-spinner';
import { LocalOffers } from './local-offers';
import { useStore } from '../../hooks-store/store';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';
import { useTranslations } from '../../services/use-translations';
import InputZip from '../../components/input-zip';

export type DealerView = 'map' | 'list';

interface Tier1SimplifiedVersionPros {
  readonly modelKey: string;
  readonly modelName: string;
  readonly carlineId?: string;
}

export const Tier1SimplifiedVersion: React.FC<Tier1SimplifiedVersionPros> = props => {
  const [store] = useStore();
  const { modelKey, modelName, carlineId } = props;
  const { specialOffersRedesignEnable } = useFeatureAppConfig();
  const translations = useTranslations().tier1simplifiedVersion;

  return (
    <StyledLandingWrapper>
      {store.isStoreCloned && !store.pendingProcessState ? (
        <LayoutWrapper>
          {specialOffersRedesignEnable ? (
            <LocalOffers
              offersData={store.offersData}
              dealer={store.dealer}
              modelKey={modelKey}
              modelName={modelName}
              modelsConfig={store.modelsConfig}
              carlineId={carlineId}
            />
          ) : (
            <>
              <StyledInputZipWrapper
                specialOffersRedesignEnable={specialOffersRedesignEnable}
              >
                <StyledInputZipContainer
                  specialOffersRedesignEnable={specialOffersRedesignEnable}
                >
                  <InputZip
                    placeholder={translations.inputZipPlaceholder}
                    label={translations.inputZipLabel}
                    errorText={translations.inputZipError}
                    maxLength={5}
                  />
                </StyledInputZipContainer>
              </StyledInputZipWrapper>
              <LocalOffers
                offersData={store.offersData}
                dealer={store.dealer}
                modelKey={modelKey}
                modelName={modelName}
                modelsConfig={store.modelsConfig}
                carlineId={carlineId}
              />
            </>
          )}
        </LayoutWrapper>
      ) : (
        <LoaderContainer>
          <LoaderSpinner />
        </LoaderContainer>
      )}
    </StyledLandingWrapper>
  );
};
