import React from 'react'
import {TextCustom, TextAppearance, TextAlignment, TextSize, TextWeight, TextLineHeight, Breakpoints} from '@vw-marketing/us-components';
import {
  StyledHeaderGroup,
  StyledHeaderWrapper,
  StyledSubHeaderWrapper
} from './styled';
import { useTranslations } from '../../services/use-translations';

interface NoDealersProps {

}
export const NoDealers: React.FC<NoDealersProps> = () => {
         const translations = useTranslations().offersSection.rightContainerSection;

         return (
           <StyledHeaderGroup className="header-group">
             <StyledHeaderWrapper>
               <TextCustom
                 appearance={TextAppearance.headline200}
                 style={{
                   [Breakpoints.default]: {
                     fontWeight: TextWeight.bold,
                     fontSize: TextSize.TextSize32,
                     lineHeight: TextLineHeight.TextLineHeight36,
                     textAlign: TextAlignment.center
                   }
                 }}
               >
                 {translations.noDealers}
               </TextCustom>
             </StyledHeaderWrapper>
             <StyledSubHeaderWrapper>
               <TextCustom
                 appearance={TextAppearance.headline200}
                 style={{
                   [Breakpoints.default]: {
                     fontSize: TextSize.TextSize20,
                     lineHeight: TextLineHeight.TextLineHeight24,
                     fontWeight: TextWeight.regular,
                     textAlign: TextAlignment.center
                   }
                 }}
               >
                 {translations.anotherZip}
               </TextCustom>
             </StyledSubHeaderWrapper>
           </StyledHeaderGroup>
         );
       };