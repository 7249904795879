import React, { memo, useEffect, useState } from 'react';

import { SlickSlider } from '@vw-marketing/us-components';

import {
  ModelOrder,
  localModelOrder,
} from '../../../../config/tier-1/model-order';
import { AppConstants } from '../../../../utils/app-constants';

import { useFeatureServices } from '../../../../context/use-feature-services';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import {
  DealerModel,
  ImageOfferData,
  OfferInformation,
  OffersModel,
} from '../../../../hooks-store/typings/incentive-store';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { transformIrisUrl } from '../../../../utils/general';
import {
  onHandleCarouselLoaded,
  onSlideActionTracking,
} from '../../../../utils/tagging-helpers';
import { NewOfferCard } from '../offer-card/NewOfferCard';
import { NewStyleCarouselContainer } from './styled';

interface OfferCardSectionProps {
  readonly offerInformation: OfferInformation;
  readonly dealer?: DealerModel;
  readonly isTier1?: boolean;
}

export const NewOfferCardSection = memo(
  ({
    offerInformation,
    dealer,
    isTier1,
  }: OfferCardSectionProps): JSX.Element | null => {
    const [imageMap, setImageMap] = useState<ImageOfferData>(
      offerInformation.imageData,
    );

    const trackingManager = useTrackingManager();
    const {
      faHostServerUrl = '',
      InventoryRedirectFAName,
      InventoryRedirectPageName,
      modelOrder: modelOrderConfig,
      mode
    } = useFeatureAppConfig();

    const useModelOrder = modelOrderConfig ? modelOrderConfig : localModelOrder;
    const { 'navigation-service': navigationService } = useFeatureServices();

    /**
     * Use the model name to create a slug
     */
    const getSlug = () => {
      const modelOrderEle: ModelOrder = useModelOrder.filter(
        (ele: ModelOrder) => ele.modelKey === offerInformation.modelKey,
      )[0];

      return modelOrderEle?.modelName?.toLowerCase().replace(/ /g, '-');
    };

    const navigateToInventory = navigationService.navigateTo(
      InventoryRedirectPageName
        ? InventoryRedirectPageName
        : AppConstants.InvRedirectPagename,
      {
        [InventoryRedirectFAName
          ? InventoryRedirectFAName
          : AppConstants.InvRedirectFAName]: `/inventory/results/${getSlug()}`,
      },
    );

    const navigateToInventoryHref = navigateToInventory?.createHref();

    useEffect(() => {
      if (offerInformation.imageData) {
        setImageMap(offerInformation.imageData);
      }
    }, [offerInformation.imageData]);

    // Tracking Function
    const onHandleSlideAction = (
      type: 'click' | 'swipe',
      direction: string,
    ) => {
      onSlideActionTracking(
        type,
        direction,
        offerInformation,
        trackingManager,
        undefined,
        dealer,
        isTier1,
      );
      onHandleCarouselLoaded(
        offerInformation,
        trackingManager,
        isTier1,
        undefined,
        dealer,
      );
    };

    const sliderKey = offerInformation.offers.length;

    return (
      <NewStyleCarouselContainer>
        <SlickSlider
          key={sliderKey}
          visibleCards={mode === "modal" ? 2.5 : 3.5}
          visibleCardOnTablet={1.5}
          showArrows={true}
          onActionTrackingTrigger={onHandleSlideAction}
        >
          {offerInformation.offers.map((offer: OffersModel) => (
            <NewOfferCard
              key={offer.dealId}
              modelInventoryHref={navigateToInventoryHref}
              offer={{
                ...offer,
                modelKey: offerInformation?.modelKey,
                modelDisplayName: offerInformation?.modelDisplayName,
                mediaImageUrl:
                  transformIrisUrl(
                    imageMap?.modelImage,
                    AppConstants.IrisImgPositionE13,
                    '960',
                    '432',
                    imageMap?.modelYear,
                    offerInformation?.modelKey,
                    imageMap?.isNewImage,
                  ) ?? faHostServerUrl + AppConstants.Ngw6Logo960x432,
                modelCustomCta: offerInformation.modelCustomCta,
              }}
              isTier1
              modelTag={offerInformation.modelTag}
            />
          ))}
        </SlickSlider>
      </NewStyleCarouselContainer>
    );
  },
);
